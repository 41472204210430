type EnvType = 'development' | 'production';

interface IPaging {
  page?: number;
  pageSize?: number;
  type?: string;
}

interface IPhoneProps {
  mobile: string;
  countryCode: string;
  shareId?: string;
}

const baseUrls = {
  // development: "http://192.168.199.29:8090", // 测试
  development: 'http://192.168.201.183:8090', // 测试
  // development: 'https://appointment-api.bbgame.com.tw', // 测试
  production: 'https://appointment-api.bbgame.com.tw', // 生产
};

const prefixs = {
  development: '/api',
  production: '/api',
};

const BaseUrl = baseUrls[process.env.NODE_ENV as EnvType];
const prefix = prefixs[process.env.NODE_ENV as EnvType];

const requestUrl = `${BaseUrl}${prefix}`;

// 创建分享id
export const IGetShareId = ({ authId }: { authId: string }) => {
  return {
    url: `${requestUrl}/appointment/createShareId`,
    method: 'post',
    data: {
      authId,
    },
  };
};

// facebook 登录
export const ILoginFB = ({
  facebookAccessToken,
}: {
  facebookAccessToken: string;
}) => {
  return {
    headers: {
      channelId: 4,
      gameId: 41,
      packageId: 119,
    },
    url: `${requestUrl}/appointment/facebookLogin`,
    method: 'post',
    data: {
      appId: 119,
      channelId: 4,
      gameId: 41,
      facebookAccessToken,
    },
  };
};

// 手机预约
export const IPhoneReserve = ({
  mobile,
  countryCode,
  shareId = '',
}: IPhoneProps) => {
  return {
    url: `${requestUrl}/appointment/mobile`,
    method: 'post',
    data: {
      mobile,
      countryCode,
      shareId,
    },
  };
};

// 手机预约数量查询
export const IPhoneReserveNum = () => {
  return {
    url: `${requestUrl}/appointment/searchCount`,
    method: 'post',
  };
};

// 手机预约数量设置
export const IPhoneReserveSet = ({ count }: { count: number }) => {
  return {
    url: `${requestUrl}/appointment/setCount`,
    method: 'post',
    data: { count },
  };
};

// 分享奖励领取
export const IShareReward = ({
  authId,
  seq,
}: {
  authId: string;
  seq: number;
}) => {
  return {
    url: `${requestUrl}/appointment/shareSign`,
    method: 'post',
    data: { authId, seq },
  };
};

// 新闻页
export const getNewest = ({ page = 1, pageSize = 5, type }: IPaging) => {
  let params: any = { page, pageSize };
  if (type) params = { ...params, type };
  let string = '';
  for (const key in params) {
    string += params[key] ? `&${key}=${params[key]}` : '';
  }
  return {
    url: `https://webbackend.bb.game/authless/article?project_id=29${string}`,
    method: 'get',
  };
};

// 新闻详情
export const getDetailsById = (id: number) => {
  return {
    url: `https://webbackend.bb.game/authless/article?project_id=29&id=${id}`,
    method: 'get',
  };
};

// 手机验证码发送
export const sendPhoneCode = ({ mobile, countryCode }: IPhoneProps) => {
  return {
    url: `${requestUrl}/appointment/sendMoblieSms`,
    method: 'post',
    data: {
      mobile,
      countryCode,
    },
  };
};

// 手机查询礼品码
export const getGiftCodeByPhone = ({
  mobile,
  countryCode,
  verifyCode,
}: { verifyCode: string } & IPhoneProps) => {
  return {
    url: `${requestUrl}/appointment/searchMobileGiftCode`,
    method: 'post',
    data: {
      mobile,
      countryCode,
      verifyCode,
    },
  };
};

// fb登录查询礼品码
export const getGiftCodeByFB = ({ authId }: { authId: string }) => {
  return {
    url: `${requestUrl}/appointment/searchShareGiftCode`,
    method: 'post',
    data: { authId },
  };
};

// 获取用户信息
export const getUserInfoById = ({ authId }: { authId: string }) => {
  return {
    url: `${requestUrl}/appointment/getLoginStatus?authId=${authId}`,
    method: 'post',
    data: {},
  };
};

// 共有数据
export const REWARDNAMES: {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
} = {
  0: '金簽*3 初級資源寶箱*5 良質裝備圖紙*1',
  1: '銀簽*5',
  2: '初級資源寶箱*3',
  3: '聲望*1888',
  4: '金簽*3',
};

export const AreaOptions = [
  {
    id: 1,
    text: '台灣+886',
    aCode: '886',
    placeholder: '9xxxxxxxx(共9位)',
    limit: 9,
    reg: /^[9]\d{8}$/,
  },
  {
    id: 2,
    text: '香港+852',
    aCode: '852',
    placeholder: '6/9/5xxxxxxx(共8位)',
    limit: 8,
    reg: /^[6|9|5]\d{7}$/,
  },
  {
    id: 3,
    text: '澳門+853',
    aCode: '853',
    placeholder: '6xxxxxxx(共8位)',
    limit: 8,
    reg: /^[6]\d{7}$/,
  },
  // {
  //   id: 4,
  //   text: "大陆+86",
  //   aCode: "86",
  //   placeholder: "",
  //   limit: 11,
  //   reg: /^[1]\d{10}$/,
  // },

  {
    id: 5,
    text: '新加坡+65',
    aCode: '65',
    placeholder: '8/9xxxxxxx(共8位)',
    limit: 8,
    reg: /^[8,9]\d{7}$/,
  },

  {
    id: 6,
    text: '馬來西亞+60',
    aCode: '60',
    placeholder: '1xxxxxxxx(共9/10位)',
    limit: 10,
    reg: /^[1]\d{8,9}$/,
  },
];

// 招募

// 手机招募
export const IPhoneRecruit = ({
  mobile,
  countryCode,
  shareId = '',
}: IPhoneProps) => {
  return {
    url: `https://recruit-api.bbgame.com.tw/api/appointment/mobile`,
    method: 'post',
    data: {
      mobile,
      countryCode,
      shareId,
    },
  };
};

// 记录分享成功
export const onShareSuccess = async ({ authId }: { authId?: string }) => {
  try {
    await fetch(`${requestUrl}/appointment/facebookShare`, {
      method: 'POST',
      body: JSON.stringify({ authId }),
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        gameId: 41,
      },
    });
  } catch (err) {
    console.log(err);
  }
};
