import { AreaOptions } from "@/api"
import _ from "lodash"

// 设置会话缓存
export const setSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, value)
}
// 获取会话缓存
export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key)
}

export const debounce = _.debounce
export const isEmpty = _.isEmpty
export const throttle = _.throttle

// 验证手机号码格式
// export const verifyPhone = (phone: string, limit: number = 9) => {
//   let flag = true
//   if (limit === 8) {
//     flag = !/^[1-9]\d{7}$/.test(phone)
//   } else if (limit === 9) {
//     flag = !/^[1-9]\d{8}$/.test(phone)
//   }
//   if (flag) {
//     alert("請輸入正确的手機號碼")
//     return false
//   }

//   return true
// }

// 验证手机号码格式
export const verifyPhone = (phone: string, aCode: string) => {
  const data = AreaOptions.find((item) => item.aCode === aCode)
  if (!data?.reg.test(phone)) {
    alert("請輸入正确的手機號碼")
    return false
  }
  return true
}

// 限制手机号码长度，多余的截取掉
export const splitPhone = (phone: string, aCode: string) => {
  const data = AreaOptions.find((item) => item.aCode === aCode) || { limit: 9 }
  let value = phone.replace(/[^0-9]+/g, "")
  if (value.length > data.limit) value = value.slice(0, data?.limit)

  return value
}
