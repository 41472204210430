import { ElonRoute } from '.';

const routes: ElonRoute[] = [
  // 默认页面
  // {
  //   path: "/",
  //   redirect: "/home",
  //   key: "default",
  // },

  {
    path: '/',
    key: 'default',
    // redirect: "/CBT", // 招募
    // redirect: '/reserve', // 预约
    redirect: '/home', // 官网
  },

  // 带 layout 的页面
  {
    path: '/home',
    type: 'layout',
    component: '/Global',
    key: 'layout',

    routes: [
      {
        title: '首页',
        index: true,
        component: '/Home',
        key: 'home',
      },
      {
        title: '新闻',
        path: '/home/news',
        component: '/News',
        key: 'news',
      },
      {
        title: '详情',
        path: '/home/news/detail/:id',
        component: '/NewsDetail',
        key: 'newsDetail',
      },

      // 局部 404 页面
      {
        title: '404NotFound',
        path: '*',
        type: 'components',
        component: '/NotFound',
        key: 'homeNotFound',
      },
    ],
  },

  // 隐藏 layout 的页面
  // {
  //   title: '预热',
  //   path: '/home',
  //   component: '/Reserve',
  //   key: 'reserve',
  // },
  // {
  //   title: '预热',
  //   path: '/reserve',
  //   component: '/Reserve',
  //   key: 'reserve',
  // },

  {
    title: '招募',
    path: '/CBT',
    component: '/Recruit',
    key: 'recruit',
  },

  // 全局 404页面
  {
    title: '404',
    path: '*',
    type: 'components',
    component: '/NotFound',
    key: 'globalNotFound',
  },
];

export default routes;
