import { createContext, Dispatch, useEffect, useReducer } from "react"
import { getSessionStorage } from "./utils"
import PageRoutes from "./routes/index"
import "./index.css"

type InitialStateType = {
  hide: boolean
  login: boolean
  reserveNumber?: number
  userInfo?: object
}

export enum ActionKind {
  Hide_common = "HIDE_COMMON",
  Login = "LOGIN",
  Update = "UPDATE",
}

type ActionType = {
  type?: ActionKind
  payload?: any
}

const initialState: InitialStateType = {
  hide: false,
  login: false,
  reserveNumber: 0,
  userInfo: {},
}

export const AppContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const Reducer = (
  state: InitialStateType,
  action: ActionType
): InitialStateType => {
  const { type, payload } = action
  switch (type) {
    case ActionKind.Hide_common:
      return {
        ...state,
        hide: !state.hide,
      }
    case ActionKind.Login:
      return {
        ...state,
        login: true,
      }
    case ActionKind.Update:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

const App = () => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  useEffect(() => {
    // 存在登录态，则默认是登录状态
    const loginStatus = getSessionStorage("login")
    if (loginStatus) {
      dispatch({ type: ActionKind.Login })
    }
  }, [])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <PageRoutes />
    </AppContext.Provider>
  )
}

export default App
