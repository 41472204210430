import React, { Suspense, lazy, Component, ExoticComponent } from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"

import { default as rootRoutes } from "./routes"

export interface ElonRoute {
  key?: string
  title?: string
  path?: string
  index?: boolean
  component?: Component | string
  redirect?: string
  type?: "layout" | "pages" | "components"
  routes?: ElonRoute[]
}

// 生成嵌套路有
export const genRoutesDom = (routes?: ElonRoute[] | null) => {
  if (!Array.isArray(routes)) return null

  return routes.map((route) => {
    const {
      path,
      index,
      component,
      redirect,
      type = "pages",
      key,
      routes: nestedRoutes,
    } = route

    // 直接引入的组件
    const isReactComponent =
      component && React.Component.isPrototypeOf(component)

    // 当前渲染内容
    const ElementRenderer: Component | ExoticComponent = isReactComponent
      ? (component as Component)
      : (lazy(() => import(`@/${type}${component}`)) as ExoticComponent)

    // WARN 查看解决方案
    const Page = ElementRenderer as any

    // 匹配路径
    const hasNested = Array.isArray(nestedRoutes) && nestedRoutes.length > 0
    const hasParam = path && /\/:[a-zA-Z]$/gi.test(path)
    const finalPath =
      !hasNested && !hasParam && path !== "*"
        ? `${path}/*` // 作为节点页面， 但是不包含路径参数
        : path // 普通路径

    // 渲染 element
    let element = null

    if (redirect) {
      element = <Navigate to={redirect} replace />
    } else {
      element = (
        <Suspense fallback={<div></div>}>
          <Page />
        </Suspense>
      )
    }

    const routeProps = index
      ? { index: true, element } // IndexRouteProps
      : { path: finalPath, element } // PathRouteProps | LayoutRouteProps

    // 渲染路由节点
    return (
      <Route key={key} {...routeProps}>
        {/* 渲染子路由节点 */}
        {genRoutesDom(nestedRoutes)}
      </Route>
    )
  })
}

const PageRoutes = () => {
  return (
    <Router basename="/">
      <Routes>{genRoutesDom(rootRoutes)}</Routes>
    </Router>
  )
}

export default PageRoutes
